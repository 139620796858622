import { CalendarJob } from "@/models/entities/calendar-job.interface";
import { Employee } from "@/models/entities/employee.interface";
import { Paginated } from "@/models/utils/paginated.interface";
import { Pagination } from "@/models/utils/pagination.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "emergencies";

class EmergenciesService extends ApiService {
  getAllEmergencies(pagination: Pagination, signal: AbortSignal = null) {
    return this.getAll<Paginated<CalendarJob>>(CONTROLLER, {
      params: pagination,
      signal: signal,
    });
  }
  getAllEmployeesForReplacementByCalendarJobId(calendarJobId: number) {
    return this.getByID<number, Employee[]>(
      `${CONTROLLER}/employeesforreplacement`,
      calendarJobId
    );
  }
}

export const emergencies = new EmergenciesService();
