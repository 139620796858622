










































import { Component, Vue } from "vue-property-decorator";
import {
  EMERGENCIES_HEADER,
  TableHeader,
} from "@/models/utils/c-table.interface";
import { CalendarJob } from "@/models/entities/calendar-job.interface";
import { ActionRequest } from "@/models/requests/actions-request.interface";
import { CDrawer, DrawerEmployee } from "@/models/utils/c-drawer.interace";
import { emergencies } from "@/services/api/emergencies.service";
import { calendarJob } from "@/services/api/calendarJob.service";
import { utils } from "@/services/api/utils.service";
import { Pagination } from "@/models/utils/pagination.interface";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import TablesPaginationModule from "@/store/modules/tables_pagination.module";
import UtilsModule from "@/store/modules/utils.module";
import OverlayModule from "@/store/modules/overlay.module";
import NavigationDrawerModule from "@/store/modules/navigationDrawer.module";
import SearchModule from "@/store/modules/search.module";
import { Actions } from "@/models/entities/action.interface";
import { CIcon } from "@/models/utils/c-icon.interface";
import SnackbarModule from "@/store/modules/snackbar.module";
import { Roles } from "@/models/entities/role.interface";
import AuthModule from "@/store/modules/auth.module";
import { Audit } from "@/models/entities/audit.interface";
const snackbarModule = getModule(SnackbarModule, store);
const authModule = getModule(AuthModule, store);
const searchModule = getModule(SearchModule, store);
const overlayModule = getModule(OverlayModule, store);
const tablesPaginationModule = getModule(TablesPaginationModule, store);
const navigationDrawerModule = getModule(NavigationDrawerModule, store);
const utilsModule = getModule(UtilsModule, store);

const CDrawerBody = () =>
  import(
    /* webpackChunkName: "CDrawerBody" */ "@/components/CosmopolDrawer/components/CDrawerBody.vue"
  );

@Component({
  components: {
    "c-drawer-body": CDrawerBody,
  },
})
export default class Emergencies extends Vue {
  private items: Audit[] = [];
  private isLoading = false;
  private serverItemsLength = 0;
  private source: AbortController = null;
  private timeInterval: number = null;
  private pagination = null as Pagination;
  private drawerItem = {} as CDrawer;
  private showDrawer = false;
  private gpgDialog = {
    show: false,
    title: "",
    message: "",
    confirmationBtnLabel: null,
    icon: null,
    item: null as CalendarJob,
  };

  get vuexPagination(): Pagination {
    return tablesPaginationModule.emergencies;
  }

  get cantEdit(): boolean {
    return authModule.roles.indexOf(Roles.CANT_EDIT_JOB_STATUS) > -1;
  }

  get headers(): TableHeader[] {
    const h = EMERGENCIES_HEADER;
    if (this.cantEdit) h.pop();
    return h;
  }
  private beforeDestroy() {
    clearInterval(this.timeInterval);
  }

  private async created(): Promise<void> {
    this.pagination = this.vuexPagination;
    this.$set(this.pagination, "search", searchModule.search);
    tablesPaginationModule.setEmergenciesPagination(this.pagination);
    await Promise.all([this.loadActions(), this.loadTableItems()]);
  }

  private mounted(): void {
    try {
      this.timeInterval = setInterval(async () => {
        await this.loadTableItems();
      }, 30000);
    } catch {
      clearInterval(this.timeInterval);
    }
  }

  private async loadActions(): Promise<void> {
    const context = this.$route.name.toLowerCase();
    let r = await utils.getActionsByContext(context);
    utilsModule.setActions(r.data);
  }

  private async loadTableItems(): Promise<void> {
    this.isLoading = true;
    this.source && this.source.abort();
    this.source = new AbortController();
    if (this.vuexPagination) this.pagination = this.vuexPagination;
    let r;
    r = await emergencies.getAllEmergencies(
      this.pagination,
      this.source.signal
    );
    if (r.message !== "canceled") {
      if (this.checkPagination(r.data.totalRows)) {
        this.items = r.data.items;
        this.serverItemsLength = r.data.totalRows;
        this.isLoading = false;
      } else await this.loadTableItems();
    }
  }

  private checkPagination(totalRows: number): boolean {
    if (totalRows !== 0) {
      const lastPage = Math.ceil(totalRows / this.pagination.rows);
      if (this.pagination.page > lastPage) {
        this.$set(this.pagination, "page", lastPage);
        tablesPaginationModule.setEmergenciesPagination(this.pagination);
        return false;
      }
    }
    return true;
  }

  private async openDrawer(
    item: CalendarJob,
    lastActionId: number
  ): Promise<void> {
    await this.loadDrawerData(item, lastActionId);
    this.showDrawer = true;
  }

  private async loadDrawerData(
    calendarJob: CalendarJob,
    lastActionId: number
  ): Promise<void> {
    await Promise.all([
      this.loadCurrentJob(calendarJob.calendarJobId, lastActionId),
      this.loadJobs(calendarJob.calendarJobId),
      this.loadEmployeesByContractRowId(calendarJob.contractRowId),
      this.loadNotes(calendarJob.calendarJobId),
      this.loadKeepAlives(calendarJob.calendarJobId),
      this.loadEmployeesForReplacement(calendarJob.calendarJobId),
      this.loadStatistics(calendarJob.employeeId, 7),
    ]);
  }

  private async loadCurrentJob(
    calendarJobId: number,
    lastActionId: number
  ): Promise<void> {
    overlayModule.showOverlay();
    let job = await calendarJob.getCalendarJobByCalendarJobId(calendarJobId);
    if (lastActionId) this.$set(job.data, "lastActionId", lastActionId);
    this.$set(this.drawerItem, "currentJob", job.data);
    const employee: DrawerEmployee = {
      employeeFullName: job.data.employeeFullName,
      isSubstitute: job.data.isSubstitute,
      oldEmployee: job.data.oldEmployee,
    };
    navigationDrawerModule.setSelectedEmployee(employee);
  }

  private async loadJobs(calendarJobId: number): Promise<void> {
    overlayModule.showOverlay();
    let jobs = await calendarJob.getAllCalendarJobsByCalendarJobId(
      calendarJobId
    );
    this.$set(this.drawerItem, "jobs", jobs.data);
  }

  private async loadEmployeesByContractRowId(
    contractRowId: number
  ): Promise<void> {
    overlayModule.showOverlay();
    let employees = await calendarJob.getEmployeesByContractRowId(
      contractRowId
    );
    this.$set(this.drawerItem, "employees", employees.data);
  }

  private async loadNotes(calendarJobId: number): Promise<void> {
    overlayModule.showOverlay();
    let notes = await calendarJob.getNotesByCalendarJobId(calendarJobId);
    this.$set(this.drawerItem, "notes", notes.data);
  }

  private async loadStatistics(
    employeeId: number,
    days: number
  ): Promise<void> {
    overlayModule.showOverlay();
    this.$set(this.drawerItem, "showChart", false);
    let statistics = await utils.getEmployeeStampsStatistic(employeeId, days);
    this.$set(this.drawerItem, "statistics", statistics.data);
    this.$set(this.drawerItem, "showChart", true);
  }

  private async loadKeepAlives(calendarJobId: number): Promise<void> {
    overlayModule.showOverlay();
    let keepAlives = await calendarJob.getKeepAliveByCalendarJobId(
      calendarJobId
    );
    this.$set(this.drawerItem, "keepAlives", keepAlives.data);
  }

  private async loadEmployeesForReplacement(
    calendarJobId: number
  ): Promise<void> {
    overlayModule.showOverlay();
    let employees =
      await emergencies.getAllEmployeesForReplacementByCalendarJobId(
        calendarJobId
      );
    this.$set(this.drawerItem, "employeesForReplacement", employees.data);
  }

  private updatePagination(): void {
    tablesPaginationModule.setEmergenciesPagination(this.pagination);
    this.loadTableItems();
  }

  private onJobClick(item: CalendarJob): void {
    this.loadDrawerData(item, undefined);
  }

  private async onSaveNotes(note: string): Promise<void> {
    overlayModule.showOverlay();
    let notes = await calendarJob.saveNote(
      this.drawerItem.currentJob.calendarJobId,
      note
    );
    this.$set(this.drawerItem, "notes", notes.data);
  }

  private async updateTableStatus(item: CalendarJob): Promise<void> {
    switch (item.lastActionId) {
      case Actions.SUBSTITUTES:
        await this.openDrawer(item, Actions.SUBSTITUTES);
        break;
      case Actions.GPG_ONPOINT:
        this.openGpgDialog(item);
        break;
      default:
        overlayModule.showOverlay();
        await calendarJob.updateCalendarJobAction(item.calendarJobId, {
          actionId: item.lastActionId,
          employeeId: null,
        } as ActionRequest);
        await this.loadTableItems();
        break;
    }
  }

  private async updateDrawerStatus(item: CalendarJob): Promise<void> {
    if (item.lastActionId !== Actions.SUBSTITUTES) {
      if (item.lastActionId !== Actions.GPG_ONPOINT) {
        overlayModule.showOverlay();
        await calendarJob.updateCalendarJobAction(item.calendarJobId, {
          actionId: item.lastActionId,
          employeeId: null,
        } as ActionRequest);
        this.items = [];
        await Promise.all([
          this.loadTableItems(),
          this.loadNotes(item.calendarJobId),
        ]);
      }
    } else {
      this.openGpgDialog(item);
    }
  }

  private async updateSubstitutes(emplyeeId: number): Promise<void> {
    overlayModule.showOverlay();
    let req: ActionRequest = {
      actionId: Actions.SUBSTITUTES,
      employeeId: emplyeeId,
    };
    let job = await calendarJob.updateCalendarJobAction(
      this.drawerItem.currentJob.calendarJobId,
      req
    );
    this.$set(this.drawerItem, "currentJob", job.data);
    const employee: DrawerEmployee = {
      employeeFullName: job.data.employeeFullName,
      isSubstitute: job.data.isSubstitute,
      oldEmployee: null, //TODO : job.data.oldEmployee
    };
    navigationDrawerModule.setSelectedEmployee(employee);
    this.items = [];
    await Promise.all([
      this.loadTableItems(),
      this.loadNotes(job.data.calendarJobId),
    ]);
  }

  private openGpgDialog(item: CalendarJob): void {
    this.$set(this.gpgDialog, "title", item.employeeFullName);
    this.$set(
      this.gpgDialog,
      "message",
      "Stai archiviando il turno. Vuoi procedere?"
    );
    this.$set(this.gpgDialog, "icon", {
      name: "warning",
      fill: "var(--v-error-base)",
    } as Pick<CIcon, "name" | "fill">);
    this.$set(this.gpgDialog, "confirmationBtnLabel", "Archivia");
    this.$set(this.gpgDialog, "item", item);
    this.$set(this.gpgDialog, "show", true);
  }

  private async onConfirmationGPG(): Promise<void> {
    overlayModule.showOverlay();
    await calendarJob.updateCalendarJobAction(
      this.gpgDialog.item.calendarJobId,
      {
        actionId: Actions.GPG_ONPOINT,
        employeeId: null,
      }
    );
    this.items = [];
    await this.loadTableItems();
    this.showDrawer = false;
    snackbarModule.showSnackbar({
      message: `Turno di ${this.gpgDialog.item.employeeFullName} archiviato con successo`,
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 5000);
  }
}
